@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&family=Poppins:wght@100;200;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.min-sidebar-screen {
  height: calc(100vh - 60px);
}

.react-datepicker__header {
  text-align: center;
  background-color: #3e82cf;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__today-button {
  background: #3e82cf;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
  clear: left;
  color: green;
}

.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.8rem !important;
}

.react-datepicker__week-number {
  color: #ff7600 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-weight: bold !important;
 
}

.css-1tkm9rf-control {
  max-height: 1.2cm !important;
  overflow-y: auto !important;
}



/* Base styles for the frozen columns */
.frozen-column
 {
  position: sticky;
  left: 0px;
  background-color: transparent;
  box-shadow: -1px 0 2px -1px rgba(0, 0, 0, 0.5) inset; /* Adjust shadow color and opacity as needed */
  z-index: 8;
}

/* Adjust the left values based on the cumulative widths of the preceding frozen columns */
.frozen-column:nth-child(1) {
  left: -1px;

}

.frozen-column:nth-child(2) {
  left: 136px;
  width: 100px;
  background-color: rgba(224, 236, 252, 0.9); 

  ;
}
.frozen-column:nth-child(3) {
  left: 226px;
  width: 100px;
  background-color: rgba(224, 236, 252, 0.9); 
  
}
thead .frozen-column {
  background-color: #e0ecfc !important; /* Background color for the header */
  /* position: sticky; */
  top: 0;
 z-index: 11
}

tbody tr:nth-child(even) .frozen-column {
  background-color: #ecedf2; /* Semi-transparent background for odd rows */
}

tbody tr:nth-child(odd) .frozen-column {
  background-color: #ffffff; /* Semi-transparent background for even rows */
}

tbody tr.selected .frozen-column {
  background-color: rgba(191, 219, 254, 1) !important;
}
tbody tr.selected .frozen-column1 {
  background-color: rgba(191, 219, 254, 1) !important;
}



.unFrozen{
  position: sticky !important;
  background-color: #e0ecfc !important;
  z-index: 9;
}
.frozen-column1 {
  position: sticky;
  left: 0;
  background-color: inherit;
  box-shadow: inset -1px 0px 2px -1px rgba(0, 0, 0, 0.5);
  z-index: 10
}

.frozen-column1:nth-child(1) {
  left: -1px;

}

.frozen-column1:nth-child(2) {
  left: 136px;
  width: 100px;
  background-color: rgba(224, 236, 252, 0.9); /* Adjust background color with transparency */
 
}

.frozen-column1:nth-child(3) {
  left: 226px;
  width: 100px;
  background-color: rgba(224, 236, 252, 0.9); /* Adjust background color with transparency */

}
.custom-calendar{
  position: absolute !important; /* Ensure positioning is absolute */
  right: -50px;
  /* transform: none !important; Disable Popper.js transform */
  /* z-index: 9999; Ensure it appears above other elements */
}
thead .frozen-column1 {
  background-color: #e0ecfc !important; /* Background color for the header */
  /* position: sticky; */
  top: 0;
z-index: 11;
}

tbody tr:nth-child(even) .frozen-column1 {
  background-color: #ecedf2; /* Semi-transparent background for even rows */
}

tbody tr:nth-child(odd) .frozen-column1 {
  background-color: #ffffff; /* Semi-transparent background for odd rows */
}


/* column resizer */
.resizer {
  display: inline-block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  z-index: 1;
  user-select: none;
}




.resizer.ltr {
  right: 1;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
